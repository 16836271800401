@import '@/styles/mixins.scss';

.error-wrapper {
    @include flex-center;
    flex-direction: column;
    height: 100vh;

    background: var(--colors-primary-light-95);
    padding: 70px;
}

.error-logo {
    width: 80px;

    margin: auto;
    margin-bottom: 40px;
}

.error-white-wrapper {
    @include flex-center;
    flex-direction: column;
    gap: 24px;

    width: 100%;

    background: white;
    border-radius: 16px;

    box-shadow: var(--shadow-card-light);

    flex-grow: 1;

    padding: 140px;
}

.error-content-wrapper {
    @include flex-center;
    flex-direction: column;

    max-width: 672px;
}

.error-title {
    @include hero-headline;
    color: var(--colors-primary-dark-80, #041630);
    text-align: center;
}

.error-message {
    @include subtitle-L;
    color: var(--colors-gray-dark-10, #8992a1);
    text-align: center;

    max-width: 600px;
}

@media screen and (max-width: 1280px) {
    .error-wrapper {
        padding: 60px;
    }

    .error-content-wrapper {
        max-width: unset;
    }

    .error-message {
        max-width: unset;
    }
}

@media screen and (max-width: 960px) {
    .error-content-wrapper {
        max-width: 450px;
    }

    .error-white-wrapper {
        padding: 140px 100px;
    }
}

@media screen and (max-width: 764px) {
    .error-wrapper {
        padding: 40px;
    }

    .error-white-wrapper {
        padding: 120px 40px;
    }

    .error-logo {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 524px) {
    .error-wrapper {
        padding: 40px 16px;
    }

    .error-white-wrapper {
        padding: 60px 24px;
    }
}
