html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    scroll-behavior: smooth;
    overflow: hidden;
}

main {
    overflow-x: clip;
    overflow-y: auto;
    position: relative;
    min-height: 100vh;
}

* {
    box-sizing: border-box;

    -moz-osx-font-smoothing: grayscale;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke: 1px transparent;
}

:root {
    // Radius
    --radius-none: 0;
    --radius-xs: 4px;
    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
    --radius-xl: 20px;
    --radius-2xl: 24px;
    --radius-3xl: 32px;
    --radius-full: 999999px;

    // Spacing
    --sp0: 0;
    --sp2: 2px;
    --sp4: 4px;
    --sp6: 6px;
    --sp8: 8px;
    --sp12: 12px;
    --sp16: 16px;
    --sp20: 20px;
    --sp24: 24px;
    --sp32: 32px;
    --sp40: 40px;
    --sp48: 48px;
    --sp60: 60px;
    --sp80: 80px;
    --sp100: 100px;
    --sp120: 120px;
    --sp140: 140px;
    --sp180: 180px;
    --sp200: 200px;
    --sp220: 220px;

    --white: #ffffff;
    --black: #000000;

    // White
    --colors-mono-white-opacity-90: #ffffffe5;
    --colors-mono-white-opacity-80: #ffffffcc;
    --colors-mono-white-opacity-70: #ffffffb2;
    --colors-mono-white-opacity-60: #ffffff99;
    --colors-mono-white-opacity-50: #ffffff80;
    --colors-mono-white-opacity-40: #ffffff66;
    --colors-mono-white-opacity-30: #ffffff4d;
    --colors-mono-white-opacity-20: #ffffff33;
    --colors-mono-white-opacity-10: #ffffff1a;
    --colors-mono-white-opacity-5: #ffffff0d;

    // Black
    --colors-mono-black-opacity-90: #000000e5;
    --colors-mono-black-opacity-80: #000000cc;
    --colors-mono-black-opacity-70: #000000b2;
    --colors-mono-black-opacity-60: #00000099;
    --colors-mono-black-opacity-50: #00000080;
    --colors-mono-black-opacity-40: #00000066;
    --colors-mono-black-opacity-30: #0000004d;
    --colors-mono-black-opacity-20: #00000033;
    --colors-mono-black-opacity-10: #0000001a;
    --colors-mono-black-opacity-5: #0000000d;

    // Primary
    --colors-primary-light-95: #f3f8fe;
    --colors-primary-light-90: #e8f1fd;
    --colors-primary-light-80: #d0e2fc;
    --colors-primary-light-70: #b9d4fa;
    --colors-primary-light-60: #a1c6f9;
    --colors-primary-light-50: #8ab8f7;
    --colors-primary-light-40: #73a9f5;
    --colors-primary-light-30: #5b9bf4;
    --colors-primary-light-20: #448df2;
    --colors-primary-light-10: #2c7ef1;

    --colors-primary: #1570ef;

    --colors-primary-dark-10: #1365d7;
    --colors-primary-dark-20: #115abf;
    --colors-primary-dark-30: #0f4ea7;
    --colors-primary-dark-40: #0d438f;
    --colors-primary-dark-50: #0b3878;
    --colors-primary-dark-60: #082d60;
    --colors-primary-dark-70: #062248;
    --colors-primary-dark-80: #041630;
    --colors-primary-dark-90: #020b18;
    --colors-primary-dark-95: #01060c;

    // Gray
    --colors-gray-light-95: #fafafb;
    --colors-gray-light-90: #f5f6f7;
    --colors-gray-light-80: #eaecf0;
    --colors-gray-light-70: #e0e3e8;
    --colors-gray-light-60: #d6dae1;
    --colors-gray-light-50: #cbd1d9;
    --colors-gray-light-40: #c1c7d1;
    --colors-gray-light-30: #b7beca;
    --colors-gray-light-20: #adb5c2;
    --colors-gray-light-10: #a2abbb;

    --colors-gray: #98a2b3;

    --colors-gray-dark-10: #8992a1;
    --colors-gray-dark-20: #7a828f;
    --colors-gray-dark-30: #6a717d;
    --colors-gray-dark-40: #5b616b;
    --colors-gray-dark-50: #4c515a;
    --colors-gray-dark-60: #3d4148;
    --colors-gray-dark-70: #2e3136;
    --colors-gray-dark-80: #1e2024;
    --colors-gray-dark-90: #0f1012;
    --colors-gray-dark-95: #080809;

    // Error
    --colors-error-light-95: #fef6f5;
    --colors-error-light-90: #feecea;
    --colors-error-light-80: #fcd9d5;
    --colors-error-light-70: #f9b4ab;
    --colors-error-light-60: #f9b4ab;
    --colors-error-light-50: #f8a197;
    --colors-error-light-40: #f68e82;
    --colors-error-light-30: #f57b6d;
    --colors-error-light-20: #f36958;
    --colors-error-light-10: #f25643;

    --colors-error: #f0432e;

    --colors-error-dark-10: #d83c29;
    --colors-error-dark-20: #c03625;
    --colors-error-dark-30: #a82f20;
    --colors-error-dark-40: #90281c;
    --colors-error-dark-50: #782217;
    --colors-error-dark-60: #601b12;
    --colors-error-dark-70: #48140e;
    --colors-error-dark-80: #300d09;
    --colors-error-dark-90: #180705;
    --colors-error-dark-95: #0c0302;

    // Warning
    --colors-warning-light-95: #fff9f3;
    --colors-warning-light-90: #fef4e6;
    --colors-warning-light-80: #fde9ce;
    --colors-warning-light-70: #fddeb5;
    --colors-warning-light-60: #fcd39d;
    --colors-warning-light-50: #fbc784;
    --colors-warning-light-40: #fabc6b;
    --colors-warning-light-30: #f9b153;
    --colors-warning-light-20: #f9a63a;
    --colors-warning-light-10: #f89b22;

    --colors-warning: #f79009;

    --colors-warning-dark-10: #de8208;
    --colors-warning-dark-20: #c67307;
    --colors-warning-dark-30: #ad6506;
    --colors-warning-dark-40: #945605;
    --colors-warning-dark-50: #7c4805;
    --colors-warning-dark-60: #633a04;
    --colors-warning-dark-70: #4a2b03;
    --colors-warning-dark-80: #311d02;
    --colors-warning-dark-90: #190e01;
    --colors-warning-dark-95: #0c0700;

    // Success
    --colors-success-light-95: #f3fbf8;
    --colors-success-light-90: #e7f8f0;
    --colors-success-light-80: #d0f1e1;
    --colors-success-light-70: #b8e9d2;
    --colors-success-light-60: #a0e2c3;
    --colors-success-light-50: #88dbb4;
    --colors-success-light-40: #71d4a6;
    --colors-success-light-30: #59cd97;
    --colors-success-light-20: #41c588;
    --colors-success-light-10: #2abe79;

    --colors-success: #12b76a;

    --colors-success-dark-10: #10a55f;
    --colors-success-dark-20: #0e9255;
    --colors-success-dark-30: #0d804a;
    --colors-success-dark-40: #0b6e40;
    --colors-success-dark-50: #095c35;
    --colors-success-dark-60: #07492a;
    --colors-success-dark-70: #053720;
    --colors-success-dark-80: #042515;
    --colors-success-dark-90: #02120b;
    --colors-success-dark-95: #010905;

    // Radius
    --radius-none: 0;
    --radius-xs: 4px;
    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
    --radius-xl: 20px;
    --radius-2xl: 24px;
    --radius-3xl: 32px;
    --radius-full: 999999px;

    // Spacing
    --sp0: 0;
    --sp2: 2px;
    --sp4: 4px;
    --sp6: 6px;
    --sp8: 8px;
    --sp12: 12px;
    --sp16: 16px;
    --sp20: 20px;
    --sp24: 24px;
    --sp32: 32px;
    --sp40: 40px;
    --sp48: 48px;
    --sp60: 60px;
    --sp80: 80px;
    --sp100: 100px;
    --sp120: 120px;
    --sp140: 140px;
    --sp180: 180px;
    --sp200: 200px;
    --sp220: 220px;

    // Responsive sizing
    --mobile-sm: 360px;
    --mobile-lg: 524px;

    --tablet-sm: 764px;
    --tablet-lg: 960px;

    --desktop-sm: 1280px;
    --desktop-md: 1404px;
    --desktop-lg: 1920px;

    // Shadows
    --shadow-card-light: 0px 4px 40px 0px rgba(0, 0, 0, 0.02);
    --shadow-card-light-hover: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    --shadow-card-dark: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    --shadow-card-dark-hover: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
    --shadow-dropdown: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    --shadow-popover: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    --shadow-popup: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);

    // Button Shadows
    --shadow-button-primary: 0px 0px 12px 0px rgba(19, 101, 215, 0.3);
    --shadow-button-gray: 0px 0px 12px 0px rgba(162, 171, 187, 0.2);
    --shadow-button-error: 0px 0px 12px 0px rgba(216, 60, 41, 0.3);
    --shadow-button-warning: 0px 0px 12px 0px rgba(222, 130, 8, 0.3);

    // Typography responsiveness
    --hero-headline-size: 52px;
    --hero-headline-letter-spacing: 0.75px;
    --headline-1-size: 36px;
    --headline-2-size: 28px;
    --headline-3-size: 24px;
    --headline-4-size: 20px;
    --headline-5-size: 18px;
    --headline-6-size: 16px;

    --subtitle-L-size: 18px;
    --subtitle-M-size: 16px;
    --subtitle-S-size: 14px;
    --subtitle-XS-size: 12px;

    --text-L-size: 18px;
    --text-M-size: 16px;
    --text-S-size: 14px;
    --text-XS-size: 12px;

    --cta-primary-size: 16px;
    --cta-secondary-size: 16px;

    --caption-L-size: 14px;
    --caption-M-size: 12px;
    --caption-S-size: 11px;
}

@media only screen and (max-width: 764px) {
    :root {
        // Typography responsiveness
        --hero-headline-size: 40px;
        --hero-headline-letter-spacing: 0px;
        --headline-1-size: 30px;
        --headline-2-size: 24px;
        --headline-3-size: 20px;
        --headline-4-size: 18px;
        --headline-5-size: 16px;
        --headline-6-size: 14px;

        --subtitle-L-size: 16px;
        --subtitle-M-size: 16px;
        --subtitle-S-size: 14px;
        --subtitle-XS-size: 12px;

        --text-L-size: 16px;
        --text-M-size: 16px;
        --text-S-size: 14px;
        --text-XS-size: 12px;

        --cta-primary-size: 16px;
        --cta-secondary-size: 16px;

        --caption-L-size: 14px;
        --caption-M-size: 12px;
        --caption-S-size: 11px;
    }
}

video {
    clip-path: inset(1px 1px);

    &::-webkit-media-controls-panel {
        background-image: linear-gradient(transparent, transparent) !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    color: var(--colors-gray-light-50);
}

input::-moz-placeholder {
    color: var(--colors-gray-light-50);
}

input::-ms-placeholder {
    color: var(--colors-gray-light-50);
}

input::placeholder {
    color: var(--colors-gray-light-50);
}
