@import '@/styles/mixins.scss';

.overlay {
    @include flex-column;
    justify-content: flex-end;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    height: 100vh;
    height: 100dvh;
    width: 100vw;

    background: var(--colors-mono-black-opacity-40);

    z-index: 999999999999999999;
}

.popup {
    @include flex-row;
    gap: 24px;

    margin: 32px;
    padding: 32px;

    border-radius: var(--sp24);
    background: var(--white);
    box-shadow: var(--shadow-card-dark);
}

.icon-wrapper {
    @include flex-center;

    height: min-content;

    padding: 12px;
    border-radius: 10px;
    background: var(--colors-primary-light-95);
}

.icon {
    width: 24px;
    height: 24px;
}

.content {
    @include flex-row;
    gap: 24px;

    flex-grow: 1;
}

.content-wrapper {
    @include text-S;
    @include flex-column;
    gap: 12px;

    color: var(--colors-gray-dark-40);

    p {
        margin: 0;
    }

    a {
        color: var(--colors-primary);
        text-decoration: none;
    }
}

.title {
    @include headline-4;
    color: var(--colors-gray-dark-70);
}

.italic {
    font-style: italic;
}

.controls {
    @include flex-column;
    gap: 24px;

    margin-top: auto;
}

.toggles {
    @include flex-row;
    gap: 24px;
}

.toggle-wrapper {
    @include subtitle-S;
    @include flex-center;
    justify-content: flex-start;
    gap: 12px;

    color: var(--colors-gray-dark-50);
}

.buttons {
    @include flex-row;
    gap: 12px;
}

.button {
    flex-grow: 1;
}

.secondary {
    border: 1px solid var(--colors-mono-black-opacity-5) !important;
    color: var(--colors-gray-light-40) !important;
    font-weight: 500 !important;

    flex-grow: 0;
}

@media screen and (max-width: 1280px) {
    .toggles {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .toggle-wrapper {
        width: 225px;
    }
}

@media screen and (max-width: 960px) {
    .popup {
        flex-direction: column;
        gap: 32px;
    }

    .controls {
        gap: 32px;
    }

    .toggles {
        grid-template-columns: repeat(4, 1fr);
    }

    .toggle-wrapper {
        @include flex-center;
        width: unset;
    }

    .secondary {
        min-width: 250px;
    }
}

@media screen and (max-width: 764px) {
    .popup {
        gap: 20px;
    }

    .content,
    .content-wrapper {
        @include flex-center;
        flex-direction: column;

        text-align: center;
    }

    .controls {
        gap: 20px;
    }

    .toggles {
        grid-template-columns: repeat(2, 1fr);
    }

    .toggle-wrapper {
        @include flex-center;
        width: unset;
    }

    .secondary {
        min-width: unset;
    }
}

@media screen and (max-width: 524px) {
    .popup {
        margin: 16px;
        padding: 32px 16px;
    }

    .buttons {
        flex-direction: column-reverse;
        gap: 12px;
    }

    .toggles {
        gap: 16px;
    }
}
